import React from "react";
import DiscoverHeader from "./DiscoverHeader";
import Discover from "components/FrondDetails/Discover/Discover";

export default function DiscoverData() {
  return (
    <section className="bg-white rounded-4xl border-2 border-solid border-grayBorder p-5 w-full no-focus">
      <DiscoverHeader />
      <div className="w-5/6 mx-auto">
        <Discover flag={true} />
      </div>
    </section>
  );
}
