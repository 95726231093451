import React from "react";
import cardImg from "../../Assets/discover/frond-img.svg";
import { Image } from "@nextui-org/react";
import placholderIcon from "../../Assets/frond-details/placholder-icon.svg";
import arrow from "../../Assets/discover/arrow-down.svg";

export default function DiscoverHeader() {
  return (
    <div className="relative mb-[15px]">
      {cardImg ? (
        <div className="relative after:absolute after:inset-0 after:bg-discoverOverlay after:z-20 after:rounded-[20px]">
          <Image
            src={cardImg}
            alt="Frond Title"
            classNames={{ wrapper: "min-w-full rounded-[20px]" }}
            className="rounded-[20px] phone:h-[300px] h-[160px] w-full object-cover"
          />
        </div>
      ) : (
        <div className="flex justify-center phone:h-[300px] h-[160px] rounded-[20px] items-center bg-textColor">
          <Image
            classNames={{ wrapper: "z-30" }}
            src={placholderIcon}
            alt="Frond Title"
            className="w-[46px] h-[50px]"
          />
        </div>
      )}

      <div className="card-info absolute z-30 inset-0 flex flex-col justify-between p-5 text-white text-lg font-bold leading-[20.7px]">
        <div>
          <h3 className="mb-[5px]">Discover a new way to plan!</h3>
          <h4 className="text-base font-normal leading-[18.4px]">
            Based on your frond, just one step to start
          </h4>
        </div>
        <div className="flex gap-[18px] items-center">
          <p>Sara’s wedding</p>
          <Image src={arrow} alt="Arrow" className="w-[24px] h-[24px]" />
        </div>
      </div>
    </div>
  );
}
