import React, { useState } from "react";
import calendarIcon from "../../../Assets/frond-details/discover/calendar.svg";
import geolocationIcon from "../../../Assets/frond-details/discover/geolocation.svg";
import categoryIcon from "../../../Assets/frond-details/discover/category.svg";
import plusIcon from "../../../Assets/frond-details/discover/plus-circle.svg";
import userIcon from "../../../Assets/frond-details/discover/profile-user.svg";
import messageEditIcon from "../../../Assets/frond-details/discover/message-edit.svg";
import { DateRangePicker, Image } from "@nextui-org/react";
import { Controller, useForm } from "react-hook-form";
import calendar from "../../../Assets/create-frond/calendar.svg";
import arrowRight from "../../../Assets/discover/arrow-right.svg";
import { Link } from "react-router-dom";

export default function Discover({ flag }) {
  const [editDate, setEditDate] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      dateRange: "",
      location: "",
      category: "",
      recommendations: [],
      guests: "",
    },
  });
  return (
    <section className="discover flex flex-col">
      <div className="item flex justify-between items-center gap-2.5 border-b-1 border-solid border-grayBorder pb-4 mb-4">
        <div className="flex items-center gap-[13px]">
          <Image
            src={calendarIcon}
            alt="calendar"
            className="w-[24px] h-[24px]"
          />
          <div>
            <h4 className="mb-[5px] text-sm text-cardGrayColor leading-[16.1px]">
              Date
            </h4>

            {editDate ? (
              <Controller
                name="dateRange"
                control={control}
                render={({ field }) => (
                  <DateRangePicker
                    {...field}
                    variant="bordered"
                    visibleMonths={2}
                    selectorIcon={
                      <Image src={calendar} className="w-[18px] h-[18px]" />
                    }
                    isInvalid={errors.dateRange?.message ? true : false}
                    errorMessage={errors.dateRange?.message}
                    classNames={{
                      base: `mt-0 ${
                        errors.dateRange?.message ? "h-[68px]" : "h-[39px]"
                      }`,
                      label: "text-textColor font-normal text-sm",
                      inputWrapper:
                        "border-[0.86px] p-0 border-solid border-[#E5E4EB] rounded-[8px] min-h-[42px] flex-row-reverse date-box",
                      innerWrapper: "px-[18px] h-full no-action",
                      segment: "date-picker-frond text-sm font-normal",
                      selectorButton:
                        "order-first min-w-[18px] max-h-[18px] h-[18px] me-1",
                      separator:
                        "mx-0 date-picker-separator text-sm font-normal no-action",
                    }}
                  />
                )}
              />
            ) : (
              <p className="text-base font-bold text-textColor leading-[22px]">
                June 6 - June 30
              </p>
            )}
          </div>
        </div>
        {editDate ? (
          <button className="leading-[18.4px] text-white w-[82px] h-[30px] rounded-4xl bg-mainGradiant py-1.5 px-2.5 flex justify-center items-center">
            Save
          </button>
        ) : (
          <button
            onClick={() => {
              setEditDate(true);
            }}
            type="button"
            className="flex items-center gap-2.5"
          >
            <Image
              src={messageEditIcon}
              alt="Edit Item"
              className="w-[24px] h-[24px]"
            />
            <span className="text-transparent bg-clip-text bg-mainGradiant leading-[18.4px]">
              Edit
            </span>
          </button>
        )}
      </div>
      <div className="item flex justify-between items-center gap-2.5 border-b-1 border-solid border-grayBorder pb-4 mb-4">
        <div className="flex items-center gap-[13px]">
          <Image
            src={geolocationIcon}
            alt="geolocation"
            className="w-[24px] h-[24px]"
          />
          <div>
            <h4 className="mb-[5px] text-sm text-cardGrayColor leading-[16.1px]">
              Location
            </h4>
            <p className="text-base font-bold text-textColor leading-[22px]">
              Larissa, greece
            </p>
          </div>
        </div>
        <button type="button" className="flex items-center gap-2.5">
          <Image
            src={messageEditIcon}
            alt="Edit Item"
            className="w-[24px] h-[24px]"
          />
          <span className="text-transparent bg-clip-text bg-mainGradiant leading-[18.4px]">
            Edit
          </span>
        </button>
      </div>
      <div className="item flex justify-between items-center gap-2.5 border-b-1 border-solid border-grayBorder pb-4 mb-4">
        <div className="flex items-center gap-[13px]">
          <Image
            src={categoryIcon}
            alt="category"
            className="w-[24px] h-[24px]"
          />
          <div>
            <h4 className="mb-[5px] text-sm text-cardGrayColor leading-[16.1px]">
              Category
            </h4>
            <p className="text-base font-bold text-textColor leading-[22px]">
              Event
            </p>
          </div>
        </div>
        <button type="button" className="flex items-center gap-2.5">
          <Image
            src={messageEditIcon}
            alt="Edit Item"
            className="w-[24px] h-[24px]"
          />
          <span className="text-transparent bg-clip-text bg-mainGradiant leading-[18.4px]">
            Edit
          </span>
        </button>
      </div>
      <div className="item flex justify-between items-center gap-2.5 border-b-1 border-solid border-grayBorder pb-4 mb-4">
        <div className="flex items-center gap-[13px]">
          <Image src={plusIcon} alt="plus" className="w-[24px] h-[24px]" />
          <div>
            <h4 className="mb-[5px] text-sm text-cardGrayColor leading-[16.1px]">
              Recommendations
            </h4>
            <p className="text-base font-bold text-textColor leading-[22px]">
              Hotels, Flights, Restaurants, Pharmacies, Hospitals, Hairdresser
              etc
            </p>
          </div>
        </div>
        <button type="button" className="flex items-center gap-2.5">
          <Image
            src={messageEditIcon}
            alt="Edit Item"
            className="w-[24px] h-[24px]"
          />
          <span className="text-transparent bg-clip-text bg-mainGradiant leading-[18.4px]">
            Edit
          </span>
        </button>
      </div>
      <div className="item flex justify-between items-center gap-2.5 mb-[40px]">
        <div className="flex items-center gap-[13px]">
          <Image src={userIcon} alt="guests" className="w-[24px] h-[24px]" />
          <div>
            <h4 className="mb-[5px] text-sm text-cardGrayColor leading-[16.1px]">
              Guests
            </h4>
            <p className="text-base font-bold text-textColor leading-[22px]">
              6 Guests
            </p>
          </div>
        </div>
        <button type="button" className="flex items-center gap-2.5">
          <Image
            src={messageEditIcon}
            alt="Edit Item"
            className="w-[24px] h-[24px]"
          />
          <span className="text-transparent bg-clip-text bg-mainGradiant leading-[18.4px]">
            Edit
          </span>
        </button>
      </div>
      <div className="flex justify-center items-center w-full">
        <Link
          to={"/discover/suggestions"}
          className={`${
            flag
              ? "w-[142px] h-[48px] py-3 px-5 rounded-4xl gap-2.5"
              : "w-[118px] h-[39px] rounded-[27px] py-[11px] px-[25px]"
          } bg-mainGradiant flex justify-center items-center text-white leading-[18.4px] font-bold`}
        >
          <span>Discover</span>
          {flag && (
            <Image
              src={arrowRight}
              className="min-w-[24px] min-h-[24px]"
              alt="Arrow"
            />
          )}
        </Link>
      </div>
    </section>
  );
}
