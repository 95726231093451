import { Image, Input } from "@nextui-org/react";
import React from "react";
import magnifierIcon from "../../Assets/discover/magnifier.svg";
import filterIcon from "../../Assets/discover/filter-search.svg";

export default function Suggestions() {
  return (
    <section className="suggestions bg-white rounded-4xl border-2 border-solid border-grayBorder p-5 w-full no-focus">
      <div className="flex justify-between items-center gap-2.5">
        <Input
          type="text"
          placeholder="Search and discover..."
          classNames={{
            base: "input-noMargin h-[39px] discover-search-input bg-white w-[399px]",
            label: "text-textColor font-normal smallPhone:text-sm text-[12px]",
            input:
              "border-none px-[18px] ms-1.5 smallPhone:text-sm text-[10px]",
            mainWrapper: "h-[39px] bg-white",
            innerWrapper: "h-[39px]",
            inputWrapper: `border-2 py-0 px-[15px] border-solid border-grayBorder no-focus rounded-[20px] h-[39px] bg-white`,
          }}
          startContent={
            <img
              src={magnifierIcon}
              alt="Search"
              className="w-[18px] h-[18px]"
            />
          }
        />
        <button className="flex items-center gap-2">
          <Image src={filterIcon} alt="Filter" className="w-[24px] h-[24px]" />
          <span className="text-mainBlue font-bold">Filter</span>
        </button>
      </div>
    </section>
  );
}
